export const DEGEN_HOURS = [
  {
    name: "Psych",
    length: 60 / 355,
    start: {
      hour: 0,
      minute: 0
    }
  },
  {
    name: "Jackass",
    length: 30 / 355,
    start: {
      hour: 1,
      minute: 0
    }
  },{
    name: "Freaks and Geeks",
    length: 60 / 355,
    start: {
      hour: 1,
      minute: 30
    }
  },{
    name: "Trailer Park Boys",
    length: 30 / 355,
    start: {
      hour: 2,
      minute: 30
    }
  },{
    name: "Archer",
    length: 30 / 355,
    start: {
      hour: 3,
      minute: 0
    }
  },{
    name: "Doctor Who",
    length: 60 / 355,
    start: {
      hour: 3,
      minute: 30
    }
  },{
    name: "X Files",
    length: 60 / 355,
    start: {
      hour: 4,
      minute: 25
    }
  },{
    name: "Law and Order",
    length: 60 / 355,
    start: {
      hour: 5,
      minute: 25
    }
}];

export const MORNING_STUFF = [{
    name: "M.A.S.H",
    length: 30 / 240,
    start: {
      hour: 6,
      minute: 25
    }
  },
  {
    name: "Cheers",
    length: 30 / 240,
    start: {
      hour: 6,
      minute: 55
    }
  },
  {
    name: "Skins",
    length: 60 / 240,
    start: {
      hour: 7,
      minute: 25
    }
  },
  {
    name: "Smallville",
    length: 60 / 240,
    start: {
      hour: 8,
      minute: 25
    }
  },
  {
    name: "Malcolm in the Middle",
    length: 30 / 240,
    start: {
      hour: 9,
      minute: 25
    }
  },
  {
    name: "Boy Meets World",
    length: 30 / (4 * 60),
    start: {
      hour: 9,
      minute: 55
    }
}];

export const CARTOONS = [{
    name: "Futurama",
    length: 30 / 235,
    start: {
      hour: 10,
      minute: 25
    }
  },{
    name: "Batman: The Animated Series",
    length: 30 / 235,
    start: {
      hour: 10,
      minute: 55
    }
  },{
    name: "Gargoyles",
    length: 30 / 235,
    start: {
      hour: 11,
      minute: 20
    }
  },{
    name: "Dragon Ball",
    length: 30 / 235,
    start: {
      hour: 11,
      minute: 50
    }
  },{
    name: "Dragon Ball Z",
    length: 30 / 235,
    start: {
      hour: 12,
      minute: 20
    }
  },{
    name: "Dragonball Super",
    length: 30 / 235,
    start: {
      hour: 12,
      minute: 50
    }
  },{
    name: "Jackie Chan Adventures",
    length: 30 / 235,
    start: {
      hour: 13,
      minute: 20
    }
  },{
    name: "Star Wars: Clone Wars",
    length: 30 / 235,
    start: {
      hour: 13,
      minute: 50
    }
}];

export const CHILL_AFTERNOON_SHOWS = [{
    name: "Fresh Prince",
    length: 30 / 145,
    start: {
      hour: 14,
      minute: 20
    }
  },{
    name: "Good Place",
    length: 30 / 145,
    start: {
      hour: 14,
      minute: 50
    }
  },{
    name: "Saved By The Bell",
    length: 25 / 145,
    start: {
      hour: 15,
      minute: 20
    }
  },{
    name: "House",
    length: 60 / 145,
    start: {
      hour: 15,
      minute: 45
    }
  },{
    name: "How I Met Your Mother",
    length: 30 / 145,
    start: {
      hour: 16,
      minute: 45
    }
}];

export const SOLID_CABLE_SHIT = [
    {
      name: "Entourage",
      length: 35 / 155,
      start: {
        hour: 17,
        minute: 15
      }
    },{
      name: "Scrubs",
      length: 30 / 155,
      start: {
        hour: 17,
        minute: 50
      }
    },{
      name: "The Office",
      length: 30 / 155,
      start: {
        hour: 18,
        minute: 20
      }
    },{
      name: "Parks and Rec",
      length: 30 / 155,
      start: {
        hour: 18,
        minute: 50
      }
    },{
      name: "Californication",
      length: 30 / 155,
      start: {
        hour: 19,
        minute: 20
      }
    }
];

export const PRIMETIME = [
  {
    name: "Sopranos",
    length: 70 / 255,
    start: {
      hour: 19,
      minute: 50
    }
  },
  {
    name: "The Wire",
    length: 65 / 255,
    start: {
      hour: 21,
      minute: 0
    }
  },
  {
    name: "Community",
    length: 30 / 255,
    start: {
      hour: 22,
      minute: 5
    }
  },
  {
    name: "LOST",
    length: 60 / 255,
    start: {
      hour: 22,
      minute: 35
    }
  },
  {
    name: "Boondocks",
    length: 30 / 255,
    start: {
      hour: 23,
      minute: 30
    }
  }
];