import { FC, useEffect, useState, useRef, createRef, CSSProperties } from 'react';
import Block from './Block';
import Hour from './Hour';
import TimePin from './TimePin';
import { DEGEN_HOURS, MORNING_STUFF, CARTOONS, CHILL_AFTERNOON_SHOWS, SOLID_CABLE_SHIT, PRIMETIME } from '../data/blocks';

const TOTAL_SCHEDULE_WIDTH = 1920;
const BASE_HOUR_WIDTH = TOTAL_SCHEDULE_WIDTH / 24;

const ScheduleFrame: CSSProperties = {
  background: '#111111',
  borderRadius: 16,
  overflowX: 'scroll',
  padding: 16,
  height: 124,
  marginTop: 24,
}
const BlockText: CSSProperties = {
  color: '#FFFFFF'
}
const TimeFrame: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: TOTAL_SCHEDULE_WIDTH,
  height: '100%',
  position: 'relative'
}
const TimeCenter: CSSProperties = {
  display: 'flex',
  flexGrow: 1
}
const TimeDisplay: CSSProperties = {
  color: '#FFFFFF'
}

const Schedule: FC = () => {
  const scrollRef = createRef<HTMLObjectElement>();

  useEffect(() => {
    if (scrollRef.current) {
      const currentTime = new Date();
      scrollRef.current.scrollLeft = currentTime.getHours() * BASE_HOUR_WIDTH;
    }
  }, [scrollRef.current]);

  const renderTime = () => {
    const timelineSize: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const currentTime = new Date();
    const startTime = new Date();
    const endTime = new Date();

    startTime.setHours(startTime.getHours() - 2);
    endTime.setHours(endTime.getHours() + 2);

    return (
      <div style={TimeFrame}>
        {timelineSize.map(val => (<Hour key={`hour-${val}`} width={BASE_HOUR_WIDTH} hourValue={val}/>))}
        <Block shows={DEGEN_HOURS} name="DEGEN HOURS" color="#0468BF" width={BASE_HOUR_WIDTH} start={{
          hour: 0,
          minute: 0
        }} length={385}/>
        <Block shows={MORNING_STUFF} name="MORNING STUFF" color="#023E73" width={BASE_HOUR_WIDTH} start={{
          hour: 6,
          minute: 25
        }} length={240}/>
        <Block shows={CARTOONS} name="CARTOONS" color="#07B0F2" width={BASE_HOUR_WIDTH} start={{
          hour: 10,
          minute: 25
        }} length={235}/>
        <Block shows={CHILL_AFTERNOON_SHOWS} name="CHILL AFTERNOON SHOWS" color="#F28705" width={BASE_HOUR_WIDTH} start={{
          hour: 14,
          minute: 20
        }} length={175}/>
        <Block shows={SOLID_CABLE_SHIT} name="SOLID CABLE SHIT" color="#44803F" width={BASE_HOUR_WIDTH} start={{
          hour: 17,
          minute: 15
        }} length={155}/>
        <Block shows={PRIMETIME} name="PRIMETIME" color="#005C53" width={BASE_HOUR_WIDTH} start={{
          hour: 19,
          minute: 50
        }} length={255}/>
        <TimePin hourWidth={BASE_HOUR_WIDTH}/>
      </div>
    );
  }

  return (
      <div style={ScheduleFrame} ref={scrollRef}>
        {renderTime()}
      </div>
  );
}

export default Schedule;