import '../css/ticker.css';

interface TickerProps {
    message: String
}

const Ticker = ({message} : TickerProps) => {
    return (
            <div className="basic-styling ticker-tape-container">
                <div className="ticker-tape jetbrains-mono">
                    <span>{message}</span>
                </div>
                <div className="ticker-tape jetbrains-mono" aria-hidden="true">
                    <span>{message}</span>
                </div>
            </div>
    )
}

export default Ticker;