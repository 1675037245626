import { useRef, useEffect, CSSProperties } from "react";
import { Tag } from "antd";
import { MessageItemInterface } from "./MessageItem";
import MessageItem from "./MessageItem";
import { SocketIO } from "../../socketio";

export interface MessageListProps {
    messages: MessageItemInterface[] | undefined
}

const MessageList = (props: MessageListProps) => {

    useEffect(() => {
        SocketIO.on('message', onMessageScroll);
        return () => {
            SocketIO.off('message', onMessageScroll);
        }
    }, []);

    const scrollRef = useRef<HTMLDivElement>(null);
    const renderMessage = (message: MessageItemInterface) => {
        switch(message.type) {
            case 'join':
                return <Tag bordered={false} key={`MID-${message.name}-${message.date}`}>{message.message}</Tag>
            default:
                return <MessageItem key={`MID-${message.name}-${message.date}`} message={message}/>
        }
    }

    const onMessageScroll = () => {
        /*setTimeout(() => {
            if (scrollRef && scrollRef.current) {
                scrollRef.current.lastElementChild?.scrollIntoView();
            }
        }, 250);*/
    }

    const getMessageList = (messages: MessageItemInterface[] | undefined) => {
        const MessageListFrame: CSSProperties = {
            overflowY: 'scroll',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: 8,
            marginBottom: 8,
            marginTop: 8,
            minHeight: 240,
            maxHeight: 240,
            borderRadius: 12,
            padding: 12,
            borderWidth: 3,
            borderColor: '#141414',
            borderStyle: 'solid'
        }
        return (
            <div style={MessageListFrame} ref={scrollRef}>
                {messages && messages.map(message => renderMessage(message))}
            </div>
        )
    }

    return (
        getMessageList(props.messages)
    )
};

export default MessageList;