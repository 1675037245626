import { FC, CSSProperties, useState, useEffect } from "react";
import { ConfigProvider, theme } from "antd";

import Player from "./components/Player";
import Chat from "./components/chat/Chat";
import Sidebar from "./components/Sidebar";
import { ChatProvider } from "./providers/ChatProvider";
import { StorageProvider } from "./providers/StorageProvider";
import Settings from "./components/Settings";
import Schedule from "./components/Schedule";
import Ticker from "./components/Ticker";
import './css/holiday-lights.css';
import './css/general.css';


const MOBILE_BREAKPOINT: number = 600;
const isMobile: boolean = process.env.REACT_APP_IS_MOBILE ? process.env.REACT_APP_IS_MOBILE === 'true' : window.innerWidth < MOBILE_BREAKPOINT;

enum APP_TAB {
  CHAT,
  SETTINGS
}

const DesktopFrameStyling: CSSProperties = {

}
const MobileFrameStyling: CSSProperties = {
  width: '100%'
}
const LayoutStyling: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  width: 900,
  position: 'relative',
  alignContent: 'center',
  marginTop: 24
}
const ContentStyling: CSSProperties = {

}
const SideStyling: CSSProperties = {
  display: 'flex'
}
const SideContent: CSSProperties = {
  display: 'flex',
  flexDirection: 'column'
}
const SideBySide: CSSProperties = {
  flexDirection: 'column',
  position: 'relative'
}

const App: FC = () =>  {
  const renderSidebar = () => {
    return (
      <ChatProvider>
        <Chat isMobile={isMobile}/>
      </ChatProvider>
    );
  }
  const renderLights = () => {
    return (
      <div>
        <ul className="holiday_lights">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    )
  }
  const renderSchedule = () => {
      return(
        <Schedule/>
      );
  }

  return (
    <StorageProvider>
      <ConfigProvider theme={{
        algorithm: theme.darkAlgorithm
      }}>
        <div style={LayoutStyling}>
          <div style={SideBySide}>
            <Player isMobile={isMobile}/>
            <Ticker message="Remember to stroke your shane!"/>
            {renderSchedule()}
            {renderSidebar()}
          </div>
          <Settings/>
        </div> 
      </ConfigProvider>    
    </StorageProvider>
  );
}

export default App;
