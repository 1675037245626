import { CSSProperties, useEffect, useState } from "react";

const TimePinFrame: CSSProperties = {
    height: '80%',
    background: '#FFFFFF',
    width: 4,
    position: 'absolute',
    borderRadius: 4,
    top: '14%'
}

const TimePinShade: CSSProperties = {
    position: 'absolute',
    height: '100%',
    background: '#00000066',
}

interface TimePinProps {
    hourWidth: number
}

const TimePin = ({hourWidth}: TimePinProps) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [timeValue, setTimeValue] = useState(hourWidth * currentDate.getHours() + (hourWidth * (currentDate.getMinutes() / 60)));
    useEffect(() => {
        const dateInterval = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);
        return () => clearInterval(dateInterval);
    }, []);

    useEffect(() => {
        const newVal = hourWidth * currentDate.getHours() + (hourWidth * (currentDate.getMinutes() / 60));
        setTimeValue(newVal);
    }, [currentDate]);

    return (
        <div style={{...TimePinFrame, ...{
            left: timeValue
        }}}>
            <div style={{...TimePinShade, ...{
                width: timeValue,
                left: -timeValue
            }}}>

            </div>
        </div>
    );
}

export default TimePin;