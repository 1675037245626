import { StorageContextType, StorageObjectType } from "../declarations/Storage";
import { DRAWER_DEFAULT, VIDEO_EFFECT } from "../providers/StorageProvider";

const STORAGE_KEY = 'toobz_storage';

export const LoadStorage = async () => {
    let storage: StorageObjectType = {
        user: {
            name: 'Default User',
            color: '#FFFFFF'
        },
        settings: {
            playByDefault: false,
            drawerDefault: DRAWER_DEFAULT.OPEN,
            videoEffect: VIDEO_EFFECT.NONE
        }
    };

    try {
        const savedObject = window.localStorage.getItem(STORAGE_KEY);
        if (savedObject) {
            storage = JSON.parse(savedObject) as StorageObjectType
        }
    } catch(error) {
        console.error('ERROR: Unable to load storage.');
        return Promise.reject('ERROR: Unabled to load storage.');
    }

    return Promise.resolve(storage);
}

export const SaveStorage = (obj: StorageObjectType) => {
    try {
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(obj));
    } catch (error) {
        console.error('ERROR: Unable to save local storage.');
    }
}