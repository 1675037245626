import { Tag } from 'antd';
import { CSSProperties } from 'react';

export interface MessageItemInterface {
    name: string,
    type: string,
    date: string,
    message: string,
    color?: string
};

export interface MessageItemProps {
    message: MessageItemInterface
}

const MessageItem = (props: MessageItemProps) => {
    const messageColor = props.message.color;
    const MessageFrame: CSSProperties = {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
    }
    const MessageContent: CSSProperties = {
        fontSize: 12,
        wordBreak: 'break-all',
        width: '70%',
        color: '#FFFFFF'
    }
    const MessageName: CSSProperties = {
        borderRadius: 16,
        padding: '2px 16px',
        background: `${messageColor}22`,
        color: messageColor
    }
    return (
        <div style={MessageFrame}>
            <Tag bordered={false} style={MessageName}>{props.message.name}:</Tag>
            <div style={MessageContent}>
                {props.message.message}
            </div>
        </div>
    )
}

export default MessageItem;