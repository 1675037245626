import { CSSProperties } from 'react';

const QUARTER_COLOR = '#444444'

const HourFrame: CSSProperties = {
    position: 'relative'
}
const HourText: CSSProperties = {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: 10,
    position: 'absolute',
    right: '-11.5%',
    top: 0,
    background: '#111111',
    width: '25%',
    fontWeight: 800
}
const HourIndicator: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
}
const HourQuarter: CSSProperties = {
    flexGrow: 1,
    height: '100%',
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    borderRightColor: QUARTER_COLOR
}
const QuarterShort: CSSProperties = {
    height: 14,
}
const QuarterTall: CSSProperties = {}
const QuarterStart: CSSProperties = {
   
}

interface HourProps {
    hourValue: number,
    width: number
}

const Hour = ({ hourValue, width }: HourProps) => {
    const isPM = hourValue + 1 > 12;
    return (
        <div style={{...HourFrame, ...{
            width: width,
            height: width
        }}}>
            <div style={{...HourIndicator}}>
                <div style={{...HourQuarter, ...QuarterShort}}/>
                <div style={{...HourQuarter, ...QuarterShort}}/>
                <div style={{...HourQuarter, ...QuarterShort}}/>
                <div style={{...HourQuarter, ...QuarterShort}}/>
                <span style={HourText}>
                    {isPM ? (hourValue - 11) : hourValue + 1}
                </span>
            </div>
        </div>
    );
}

export default Hour;