import { CSSProperties } from "react"

const ShowFrame: CSSProperties = {
    height: '100%',
    width: '100%'
}
const ShowTitle: CSSProperties = {
    fontSize: 12,
    lineHeight: 0,
    overflowX: 'hidden',
    textWrap: 'nowrap',
    paddingLeft: 12
}
const ShowContent: CSSProperties = {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    overflow: 'hidden',
    fontSize: 8
}
const ShowTextTitle: CSSProperties = {
    padding: 8,
    overflowX: 'hidden',
    fontWeight: 'bold',
    color: 'white',
    textShadow: '0px 1px #000'
}

interface ShowProps {
    name: string,
    color: string,
    length: number
}

const Show = ({name, color, length}: ShowProps) => {
    return (
        <div style={{...ShowFrame, ...{
            width: `${length * 100}%`
        }}}>
            <div style={{...ShowContent, ...{
                background: color
            }}}>
                <div style={ShowTextTitle}>
                    {name}
                </div>
            </div>
        </div>
    );
}

export default Show;