import { FC, CSSProperties, useRef, useState, useEffect, useContext, forwardRef } from "react";
import { VIDEO_EFFECT, StorageContext } from "../providers/StorageProvider";
import VideoJS from "./VideoJS";
import Effects from "./Effects";

import "../css/videojs-styling.css";
interface PlayerProps {
    isMobile: boolean
}

const PlayerFrame: CSSProperties = {
    margin: '0 auto',
    position: 'relative',
    width: 806,
    height: 450
}

const InnerFrame: CSSProperties = {
 overflow: 'hidden'
}
const FrameFoot: CSSProperties = {

}
const Screen: CSSProperties = {
    position: 'absolute',
    overflow: 'hidden',
    right: 0
}
const ScreenOverlay: CSSProperties = {
    background: `url(${require('../assets/screen_frame.png')})`,
    width: 800,
    height: 434,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderColor: '#000',
    borderWidth: 8,
    borderStyle: 'solid',
    zIndex: 999,
    pointerEvents: 'none',
    backgroundSize: '100% 100%'
}
const ScreenHighLight: CSSProperties = {
    width: 792,
    height: 426,
    pointerEvents: 'none',
    top: 8,
    left: 8,
    position: 'absolute',
    borderColor: '#FFFFFF11',
    borderWidth: 4,
    borderStyle: 'solid',
    zIndex: 999,
    borderRadius: 32
}

const HighlightEdge: CSSProperties = {
    border: 'solid 2px rgba(255, 255, 255, 0.1)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 90,
    borderRadius: 8,
    pointerEvents: 'none'
}
const GeneralMessage: CSSProperties = {
    color: 'white'
}

const Player: FC<PlayerProps> = ({ isMobile }) => {
    const { storage } = useContext(StorageContext);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    return (
        <div style={PlayerFrame}>
            <div style={InnerFrame}>
                <div style={Screen}>
                    {!loading && 
                    <VideoJS options={{
                        autoplay: false,
                        liveui: true,
                        controls: true,
                        width: 806,
                        height: 450,
                        sources: [{
                            src: 'http://104.131.178.74/hls/obs_stream.m3u8',
                            type: 'application/x-mpegURL'
                        },{
                            src: 'http://104.131.178.74/dash/obs_stream.mpd',
                            type: 'application/dash+xml'
                        }]
                    }} onReady={() => {

                    }}/>}
                    <Effects type={storage && storage.settings.videoEffect ? VIDEO_EFFECT.CRT : VIDEO_EFFECT.NONE}/> 
                </div>
                <div style={ScreenOverlay}/>   
                <div style={ScreenHighLight}/>            
            </div>
            <div style={FrameFoot}/>
        </div>        
    );
};

export default Player;